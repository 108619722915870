/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

export const passwordSchema = yup.object().shape({
  current_password: yup.string().required(),
  password: yup.string().min(15).required(),
  passwordConfirmation: yup
    .string()
    .min(15)
    .oneOf(
      [yup.ref('password'), null],
      'Password confirmation must match password.'
    )
    .required('Password confirm is required'),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup.string().min(15).required(),
  passwordConfirmation: yup
    .string()
    .min(15, 'Password confirmation must be at least 15 characters')
    .oneOf(
      [yup.ref('password'), null],
      'Password confirmation must match password.'
    )
    .required('Password confirm is required'),
});
